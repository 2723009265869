import React from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import "./ContentWithImage.css";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
    Link
} from "react-router-dom";
import RightAccordian from "../../assets/right.png";
import { trackEventToGA } from "../../utils/utils";
import { useLocation } from 'react-router-dom'

interface ContentWithImageProps {
    title: string,
    message: string,
    image: string,
    imageLocation: "left" | "right",
    showBookNow: boolean,
}

export default function ContentWithImage({ title, message, image, showBookNow, imageLocation }: ContentWithImageProps) {
    const desktop = useMediaQuery("(min-width:1416px)");
    const mobile = useMediaQuery("(max-width:768px)");
    const location = useLocation();
    return (
        <Box className="content-wrapper" sx={{ flexWrap: desktop ? "nowrap" : imageLocation === "left" ? "wrap" : "wrap-reverse" }}>
            {imageLocation === "left" && !mobile ? (
                <Box className="content-image-wrapper">
                    <Box className="content-image" component="img" alt="Kiefa.io" src={image} />
                </Box>
            ) : null}
            <Box className="text-wrapper" sx={{paddingLeft: imageLocation === "right" && desktop ? "10%" : "0px", paddingRight: imageLocation === "left" && desktop ? "10%" : "0px"}}>
                <Typography className="title">{title}</Typography>
                <Typography className="message">{message}</Typography>
                {showBookNow ? (
                    <Box onClick={() => trackEventToGA({category: "Button Click", action: "book_demo_clicked", label: `SubComponent on ${location.pathname}` })}>
                    <Link to="https://forms.gle/BjYG5Taw9GT5bcmaA" className="content-book-now-link">
                        <Typography className="content-book-demo-button">
                            Book Demo
                        </Typography>
                        <Box
                            className="content-checkIcon"
                            component="img"
                            alt="Kiefa.io"
                            src={RightAccordian}
                        />
                    </Link>
                    </Box>
                ) : null}
            </Box>
            {imageLocation === "right" && !mobile ? (
                <Box className="content-image-wrapper">
                    <Box className="content-image" component="img" alt="Kiefa.io" src={image} />
                </Box>
            ) : null}
        </Box>
    );
}
